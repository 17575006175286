<template>
  <el-dialog
    :title="data.title"
    :visible="visible"
    :before-close="beforeClose"
    destroy-on-close
    append-to-body
    class="r-modal-window"
  >
    <div
      class="calendar-modal-list"
    >
      <div
        v-for="item in data.list"
        :key="item.id"
        class="calendar-modal-list__item"
        @click="item.show = !item.show"
      >
        <r-block
          no-padding
          col
        >
          <calendar-marker
            :color="item.color"
          />
          <r-text>
            {{ item.time }}
          </r-text>
        </r-block>
        <r-block
          class="calendar-modal-list__card"
          no-padding
        >
          <r-block
            space-between
            no-padding
            col
          >
            <r-text>
              {{ item.event_type }}
            </r-text>
            <r-icon
              :size="20"
              :name="!item.show ? 'chevron-down' : 'chevron-up'"
            />
          </r-block>
          <r-text
            v-for="(val, vKey) in item.attributes"
            v-show="item.show"
            :key="vKey"
            type="caption"
          >
            {{ vKey }}: {{ val || '—' }}
          </r-text>
        </r-block>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  components: {
    calendarMarker: () => import('./calendar-marker')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visible: true,
      show: ''
    }
  },
  methods: {
    beforeClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.calendar-modal-list {
  display: grid;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  height: 100%;
  max-height: calc(100vh - 280px);
  overflow: auto;

  &__item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1.5rem;
    justify-content: flex-start;
    padding: 0.25rem;
    border-radius: var(--border-radius);
    cursor: pointer;

    &:hover {
      background-color: var(--accent_primary_1);
    }
  }
}
</style>
